import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useCallLogTypeList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refCallLogTypesTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'ID',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Type Name',
      sortable: true,
    },
    {
      key: 'callNatures',
      label: 'Nature of call',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'created date',
      sortable: true,
    },
    {
      key: 'createdBy.name',
      label: 'created by',
      sortable: true,
    },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage || process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalCallLogTypes = ref(0)
  const currentPage = ref(route.query.page || 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search || '')
  const sortBy = ref(route.query.sortBy || 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref(route.query.status || null)
  const createdAtFilter = ref(route.query.createdAt || '')
  const toDeletedID = ref(null)
  let initialLoad = true
  const currentDataLength = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refCallLogTypesTable.value ? refCallLogTypesTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCallLogTypes.value,
    }
  })

  const refetchData = () => {
    refCallLogTypesTable.value.refresh()
  }

  watch([searchQuery, statusFilter, createdAtFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchCallLogTypes = (ctx, callback) => {
    store
      .dispatch('app-call-log-types/fetchCallLogTypes', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        createdAt: createdAtFilter.value,
      },
      { root: true })
      .then(response => {
        const { callLogTypes, total } = response.data
        currentDataLength.value = callLogTypes.length
        callback(callLogTypes)
        totalCallLogTypes.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              createdAt: createdAtFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteCallLogType = () => {
    store
      .dispatch('app-call-log-types/deleteCallLogType', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message || '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCallLogTypes,
    tableColumns,
    perPage,
    currentPage,
    totalCallLogTypes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCallLogTypesTable,

    refetchData,
    currentDataLength,

    toDeletedID,
    deleteCallLogType,

    // Extra Filters
    statusFilter,
    createdAtFilter,
  }
}
