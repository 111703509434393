<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Created Date"
    >
      <b-input-group>
        <flat-pickr
          v-model="createdAtValue"
          class="form-control"
          placeholder="Select Date Range"
          :config="flatPickrConfig"
        />
        <b-input-group-append is-text>
          <feather-icon
            icon="CalendarIcon"
            class="cursor-pointer"
            data-toggle
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    flatPickr,
  },
  props: {
    createdAt: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      createdAtValue: this.createdAt,
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    createdAt: {
      immediate: true,
      handler(val) {
        this.createdAtValue = val
      },
    },
  },
  methods: {
    showAgainDropdown() {
      if (this.createdAtValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:createdAt', this.createdAtValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.createdAtValue = ''
      this.$emit('update:createdAt', this.createdAtValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
